import styled from 'styled-components';
import { FaMapMarker } from 'react-icons/fa';
import Icon from 'rsuite/lib/Icon';

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: #fbf7f0;
  overflow: hidden;
  border: 3px solid rgb(12, 12, 12);
`;

export const MainMarker = styled(FaMapMarker)`
  color: hsla(230, 88%, 33%, 0.7); ;
`;

export const InformationContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem;
  top: 20%;
  left: 5%;
  min-height: fit-content;
  max-width: 400px;
  background: hsla(212, 88%, 33%, 0.8);

  @media screen and (max-width: 1200px) {
    width: 200px;
  }
`;

export const InformationHeader = styled.h2`
  font-size: 1.5em;
  text-transform: uppercase;
  color: #fbf7f0;
  letter-spacing: 0.15rem;
`;

export const InformationImage = styled.img`
  margin: 5px;
  width: 100%;
  border: 2px solid #fbf7f0;
`;

export const InformationText = styled.p`
  font-size: 1.2em;
  color: #fbf7f0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1200px) {
    font-size: 0.8em;
  }
`;

export const SocialIcon = styled(Icon)`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    top: 5px;
    left: 5px;
  }
`;
