import React from 'react';
import { isMobile } from 'react-device-detect';
import {
  MapContainer,
  MainMarker,
  InformationContainer,
  InformationHeader,
  InformationImage,
  InformationText,
  SocialIcon,
} from './MapElements';
import ReactMapboxGl, { Marker, ZoomControl } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const MapBox = ({ height = null, scroll = false }) => {
  const Map = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
    scrollZoom: scroll,
    dragPan: !isMobile,
  });

  const markerStyle = { pointerEvents: 'none' };
  const containerStyle = {
    height: height ? height : '600px',
    width: '100%',
    overflow: 'hidden',
  };
  return (
    <MapContainer>
      <Map
        style={`mapbox://styles/mapbox/streets-v9?optimize=true`}
        center={
          isMobile
            ? [17.929525481805953, 44.19351063687186]
            : [17.931746, 44.193236]
        }
        zoom={[isMobile ? 15 : 16]}
        containerStyle={containerStyle}
      >
        <ZoomControl />
        <Marker style={markerStyle} coordinates={[17.931746, 44.193236]}>
          <MainMarker size={48} />
        </Marker>
      </Map>
      <InformationContainer>
        <SocialIcon
          onClick={() =>
            window.open(
              'https://www.facebook.com/Bosnavet-Doo-Zenica-543467786119443',
              '_blank'
            )
          }
          icon="facebook"
          size="2x"
          title="https://www.facebook.com/Bosnavet-Doo-Zenica-543467786119443"
        />
        <InformationHeader>Lokacija</InformationHeader>
        <InformationImage
          src="https://bosnavet.ba/wp-content/uploads/2019/10/Bosna-vet.jpg"
          alt="Bosna Vet Zgrada"
        />
        <InformationText>
          Goraždanska 36A, Zenica 72000, BiH <br /> +387 32 423 196 <br />{' '}
          bosnavet@bih.net.ba <br />
          <br />
          Pon – Pet: {isMobile && <br />} 08:30 do 16:00 <br /> Subota:{' '}
          {isMobile && <br />} 08:30 do 13:00
        </InformationText>
      </InformationContainer>
    </MapContainer>
  );
};

export default MapBox;
